<template>
<div>
   <button @click.prevent="popupModalShow = !popupModalShow" 
   class="text-primary p-0 text-decoration-none btn btn-link"
    type="button" style="font-size:12px;">CREAT NEW 
    <i class="align-bottom ri-add-circle-fill"></i></button>

   <b-modal v-model="popupModalShow" hide-footer centered
    :title="formElement.title" :size="modelSize"
    title-class="fs-6" @hide="initializeAll()">

     <component :is="formElement.type"
     v-if="popupModalShow" @closeMe="initializeAll()"
     @addItem="addItem($event)" ></component>

  </b-modal>
</div>
</template>

<script>

import CategoryForm from '@/components/forms/CategoryForm.vue';
import SubCategoryForm from '@/components/forms/SubCategoryForm.vue';
import MedicineTypeForm from '@/components/forms/MedicineTypeForm.vue';

export default {
    components:{
        CategoryForm,
        SubCategoryForm,
        MedicineTypeForm,
    },
    props:{
        modelSize:{
            type: String,
            default:'md'
        },
        formType:{
            type: String,
            default: ''
        }
    },
    data() {
        return{
            popupModalShow: false,
            formTypes:[
                {
                    type: 'category-form',
                    title: 'Create new category',
                    store: 'category/fetchAllCategories'
                },
                {
                    type: 'sub-category-form',
                    title: 'Create new sub-category',
                    store: 'subCategory/fetchAllCategories'
                },
                {
                    type: 'medicine-type-form',
                    title: 'Create new medicine type',
                    store: 'medicineType/fetchAllMedicineTypes'
                },
            ]
        }
    },
    computed:{
        formElement(){
            return this.formTypes.find(i => i.type == this.formType)
        }
    },
    methods:{
        addItem(item){
            this.$store.dispatch(this.formElement.store, true)
        },
        initializeAll(){
            this.popupModalShow = false;
        }
    },
}
</script>

<style>

</style>