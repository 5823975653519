
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Add Medicine</h5>
      <div class="card loading-viewport">
        <div class="card-body">
          <medicine-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicineForm from '@/components/forms/MedicineForm.vue';

export default {
  components: { MedicineForm },
}
</script>
