<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3">
            <div class="col-md-6">
                <label for="inputName">
                   Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-between align-items-center">
                  <label>Medicine Type </label>
                  <create-new-helper-form formType="medicine-type-form"/>
                </div>
                <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="title" label="title"
                :options="medicineTypes" 
                v-model="data.medicine_type_id" placeholder="--select type--"></multiselect>

                <div v-for="(item, index) in v$.data.medicine_type_id.$errors"
                    :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-6">
                <div class="d-flex justify-content-between align-items-center">
                  <label for="category_id">Category </label>
                  <create-new-helper-form formType="category-form"/>
                </div>
                <select @change="categoryChange()" v-model="data.category_id" 
                    name="category_id" id="category_id" class="form-select">
                <option disabled value="" >--choose category--</option>
                <option v-for="(category, coIndex) in categories" :key="coIndex" 
                :value="category.id">{{category.title}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-between align-items-center">
                  <label>Sub Category </label>
                  <create-new-helper-form formType="sub-category-form"/>
                </div>
                <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="title" label="title"
                :options="subCategories" 
                v-model="data.sub_category_id" placeholder="--select --"></multiselect>

                <div v-for="(item, index) in v$.data.sub_category_id.$errors"
                    :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-md-4">
                <label for="minimum_unit" class="form-label"> A unit name </label>
                <input v-model="data.minimum_unit" id="minimum_unit" name="minimum_unit"
                class="form-control" type="text" placeholder="Minimum unit">
                <div v-for="(item, index) in v$.data.minimum_unit.$errors"
                    :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-4">
                <label for="dosage_form" class="form-label"> Dosage form </label>
                <input v-model="data.dosage_form" id="dosage_form" name="dosage_form"
                class="form-control" type="text" placeholder="Dosage form">
            </div>
            <div class="col-md-4">
                <label for="concentration" class="form-label"> Concentration </label>
                <input v-model="data.concentration" id="concentration" name="concentration"
                class="form-control" type="text" placeholder="Concentration">
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="3" placeholder="Write description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'
import CreateNewHelperForm from '@/components/forms/CreateNewHelperForm.vue';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        IsLoading,
        Multiselect,
        CreateNewHelperForm
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isLoading: true,
            data: {
                name:"",
                minimum_unit: "",
                dosage_form: "",
                concentration: "",
                sub_category_id: "",
                medicine_type_id: "",
                category_id: "",
                description:"",
            },
            mirrorData: null,
            subCategories: []
        }
    },
    validations() {
        return {        
            data:{
                name: { required: required},
                sub_category_id: {
                    required: helpers.withMessage("Select medicine sub-category", required),
                },  
                minimum_unit: {
                    required: helpers.withMessage("Minimum unit name is required", required),
                }, 
                medicine_type_id: {
                    required: helpers.withMessage("Medicine type is required", required),
                },           
            }
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
        medicineTypes(){
            return this.$store.state.medicineType.list
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result){ this.alertError('Form not valid'); return;}
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/medicines/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.$store.commit("medicine/ADD_ITEM", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result){ this.alertError('Form not valid'); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/medicines/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("medicine/UPDATE_ITEM", response.data.data)
                            this.$emit('updatedItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.data = {...this.mirrorData}
            this.v$.$reset()
            this.$emit("closeMe")
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch('category/fetchAllCategories')
            this.$store.dispatch('medicineType/fetchAllMedicineTypes')
            .then(response =>  this.isLoading = false );
        },
        categoryChange(initialize = true){
            if(initialize){
                this.data.sub_category_id = null;
            }
            this.subCategories = [];
            if(!this.data.category_id) { return; }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/sub-categories?category_id=${this.data.category_id}`)
                .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.subCategories = response.data.data
                }
            })
        },
    },
    mounted(){
        this.getOptionData()
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.data.category_id = this.editItem.sub_category?.category_id;
            this.categoryChange(false)
        }
    }
}
</script>